import React, { Component } from 'react';
import './App.css';
import MultipleChoice from './Containers/MultipleChoice';
import MatchWords from './Containers/MatchWords';
import getQuestion from './Util/RestCalls';
import Countdown from 'react-countdown-now'
import LandingPage from './Containers/LandingPage';
import TimeUp from './Containers/TimeUp';


/**
 * need backspace button for matchword component and timer
 * 
 * need to fix how ayah is diplayed when guessing the word. It 
 * wraps the beginning blank at the end and vice versa.
 * 
 * functionality problem: once questionType state has been set 
 * to either 1 or 2, it will stay in MultipleChoice component
 * fix = loadQuestion/loadAyahDetails needs to be more generic
 * and needs to be called on by the state. need to move that
 * functionality to App.js which should randomly get next 
 * questiontype, load the state by making the fetch call,
 * and passing the state to the respective component.
 */
class App extends Component {

  componentWillMount() {
    this.setState({
      showStartButton: true,
      numberCorrect: 0,
      numberIncorrect: 0,
      isTimeUp: false
    });
    this.renderQuestionComponent = this.renderQuestionComponent.bind(this);
    this.renderQuestions = this.renderQuestions.bind(this);
    this.showQuestions = this.showQuestions.bind(this);
    this.renderTimeUp = this.renderTimeUp.bind(this);
    this.onTimeUp = this.onTimeUp.bind(this);
  }

  componentDidMount() {
    this.loadQuestion();
  }

  showQuestions() {
    this.setState({
      showStartButton: false
    })
  }

  onTimeUp() {
    this.setState({
      isTimeUp: true
    })
  }

  renderTimeUp() {
    return (
      <TimeUp numberCorrect={this.state.numberCorrect} numberIncorrect={this.state.numberIncorrect}/>
    )
  }

  async loadQuestion() {
    let newQuestionType = Math.floor(Math.random() * 3)
    let newQuestion = await getQuestion(newQuestionType);
    this.setState({ 
      question: newQuestion,
      questionType: newQuestionType
     });
  }

  incrementNumberCorrect() {
    let newNumberCorrect = this.state.numberCorrect + 1;
    this.setState({
      numberCorrect: newNumberCorrect
    })
  }

  incrementNumberIncorrect() {
    let newNumberIncorrect = this.state.numberIncorrect + 1;
    this.setState({
      numberIncorrect: newNumberIncorrect
    })
  }

  renderQuestionComponent() {
    if ( this.state.questionType === 0 || this.state.questionType === 1 ) {
      return (
        <MultipleChoice
          question={this.state.question}
          questionType={this.state.questionType}
          incrementNumberCorrect={this.incrementNumberCorrect.bind(this)}
          incrementNumberIncorrect={this.incrementNumberIncorrect.bind(this)}
          loadQuestion={this.loadQuestion.bind(this)}
        />
      )
    } else if (this.state.questionType === 2) {
        return (
          <MatchWords 
            question={this.state.question}
            incrementNumberCorrect={this.incrementNumberCorrect.bind(this)}
            incrementNumberIncorrect={this.incrementNumberIncorrect.bind(this)}
            loadQuestion={this.loadQuestion.bind(this)}
          />
        )
    }
  }

  renderQuestions() {
    return (
      <div>
        {
          this.state ?
            this.renderQuestionComponent()
          : null
        }
        <div className="container">
          <div className="row row-cols-2 mt-4 correctDisplay">
            <p className="col align-self-center">Correct: {this.state.numberCorrect}</p>
            <p className="col align-self-center">Incorrect: {this.state.numberIncorrect}</p>
          </div>
          {/* <div className="row row-cols-1 mt-4 correctDisplay">
            <Countdown
              date={Date.now() + 59000}
              intervalDelay={1000}
              renderer={props => <div className="timer">{props.seconds} seconds left</div>}
              onComplete={this.onTimeUp}
            />
          </div> */}
        </div>
      </div>
    )
  }

  render() {
    return(
        <div>
          {this.state.isTimeUp ? this.renderTimeUp() : 
            (this.state.showStartButton ? 
              <LandingPage showQuestions={this.showQuestions} /> :
              this.renderQuestions()
            )  
          }
        </div>
    )
  }
}

export default App;