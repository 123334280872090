import React, { Component } from 'react';
import '../App.css';

class MatchWords extends Component {
    constructor(props) {
        super(props);
        this.renderAnswers = this.renderAnswers.bind(this);
      }

    state = {
    }
    componentDidMount() {
        this.setState({
            correctAnswer: this.props.question.correct_answer,
            randomAnswers: this.props.question.mixed_answer,
        });
    }

    static getDerivedStateFromProps(nextProps, prevProps) {
        if (nextProps !== prevProps) {
            return {
                correctAnswer: nextProps.question.correct_answer,
                randomAnswers: nextProps.question.mixed_answer,
            }
        }
    }

    renderUserAyah(event) {
        event.preventDefault();
        let previousUserAnswer = this.state.userAnswer ? this.state.userAnswer : "";
        let newUserAnswer = event.currentTarget.textContent;
        this.setState({
            userAnswer: previousUserAnswer + " " + newUserAnswer
        })
    }

    renderAnswers() {
        let answerObject = this.state.randomAnswers;
        return (
            Object.keys(answerObject).map(key => (
                <div key={key} className="col-2 ayahbox" onClick={(e)=>this.renderUserAyah(e)}>{answerObject[key]}</div>
            ))
        )
    }

    async handleSubmit() {
        let isUserCorrect = true;
        if (this.state.userAnswer) {
            let userAnswerArray = this.state.userAnswer.trim().split(" ");
            userAnswerArray.forEach((element, index) => {
                if (element !== this.state.correctAnswer[index]) {
                    isUserCorrect = false;
                }
            })
        } else {
            isUserCorrect = false;
        }

        if (isUserCorrect) {
            alert('correct');
            this.props.incrementNumberCorrect();
            this.setState({
                userAnswer: null
            })
            this.props.loadQuestion();
        } else {
            alert("incorrect")
            this.props.incrementNumberIncorrect();
            this.setState({
                userAnswer: null
            })
            this.props.loadQuestion();
        }
    }

    render() {
        return(
            <div className="container">
                <p className="questionHeader">Click the words in order of the ayah, then click submit:</p>
                    <div className="row">
                        <div className="col-lg-12 userAnswerAyah">
                            {this.state.userAnswer}
                        </div>
                    </div>
                {
                  this.state && this.state.randomAnswers ? 
                        <div className="row justify-content-center">
                            {this.renderAnswers()}
                        </div>                    
                  : null
                }
                <div className="row justify-content-center mt-4">
                    <button type="button" 
                        className="btn btn-primary btn-lg" 
                        onClick={this.handleSubmit.bind(this)}>Submit</button>
                </div>
            </div>
        )
    }


}
export default MatchWords;
