import React, { Component } from 'react';
import '../App.css';

class MultipleChoice extends Component {
  constructor(props) {
    super(props);
    this.renderAnswers = this.renderAnswers.bind(this);
  }
  state = {
  }

  componentDidMount() {
    this.setState({
      correctAnswer: this.props.question.correct_answer,
      ayah: this.props.question.ayah,
      randomAnswers: this.props.question.random_answers
    })
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      return {
        ayah: nextProps.question.ayah,
        correctAnswer: nextProps.question.correct_answer,
        randomAnswers: nextProps.question.random_answers,
      }
    }
}

  async handleAnswerClick(event) {
    if (event === this.state.correctAnswer) {
        this.props.incrementNumberCorrect();
        this.props.loadQuestion();
    }
    else {
        this.props.incrementNumberIncorrect();
        alert("correct answer was: " + this.state.correctAnswer);
        this.props.loadQuestion();
    }
  }

  renderAnswers() {
    let answerObject = this.state.randomAnswers;
    return (
        Object.keys(answerObject).map(key => (
                <span key={key}
                    className="answerBox"
                    onClick={this.handleAnswerClick.bind(this, answerObject[key])}
                >{answerObject[key]}</span>
            ))
        )
    }

  render() {
    return(
        <div>
            {
              this.props.questionType === 0 ?
                <div className="questionHeader">
                  Which Surah is the Following Ayah a part of?
                </div>
                : <div className="questionHeader">
                  Select the Correct Word that is Missing From the Following Ayah:
                </div>

            }
            {
              this.state && this.state.ayah ?
                <div className="ayah">
                    {this.state.ayah}
                </div>
              : null
            }
            {
              this.state && this.state.randomAnswers ? 
                <div className="surahBox">
                    {this.renderAnswers()}
                </div>
              : null
            }
        </div>
    )
  }
}

export default MultipleChoice;