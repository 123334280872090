export default async function getQuestion(questionType) {
    let url = `/ayahgetter/`;
    if (questionType === 0) {
        url += `guesssurah/`;
    } else if (questionType === 1) {
        url += `guessword/`;
    }  else if (questionType === 2) {
        url += `matchwords/`;
    } 
    const response = await fetch(url);
    const ayahDetails = await response.json();
    return await ayahDetails;
}