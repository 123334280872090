import React, { Component } from 'react'
import Countdown from 'react-countdown-now'
import '../App.css'

class LandingPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            showStartButton: true,
        }
        this.renderCounter = this.renderCounter.bind(this);
    }

    startCountDown() {
        this.setState({
            showStartButton: false
        })
    }

    renderCounter() {
        return(
            <div >
                <Countdown
                    date={Date.now() + 5000}
                    intervalDelay={1000}
                    renderer={props => <div className="countdown">{props.seconds}</div>}
                    onComplete={this.props.showQuestions}
                />
            </div>
            
        )
    };


  render() {
      return (
            <div className="container">
                <div className="row align-items-center startPromptContainer">
                    <div className="col-12 align-self-center startPrompt">
                        {this.state.showStartButton ? 
                            <div>
                                <h3>Click start when you are ready</h3>
                                <button type="button" 
                                        className="btn btn-success btn-lg" 
                                        onClick={this.startCountDown.bind(this)}>
                                    Start
                                </button>
                            </div>
                            :
                            this.renderCounter()
                        }
                    </div>
                </div>
            </div>
        )
  }
}
export default LandingPage;