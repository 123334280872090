import React, { Component } from 'react'
import '../App.css'

class TimeUp extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }



  render() {
      return (
            <div className="container=fluid">
                <div className="row align-items-center startPromptContainer">
                    <div className="col-12 align-self-center startPrompt">
                    <div className="timeUp">TIME IS UP!</div>
                    <div className="timeUp">Number Correct: {this.props.numberCorrect}</div>
                    <div className="timeUp">Number Incorrect: {this.props.numberIncorrect}</div>
                    </div>
                </div>
            </div>
        )
  }
}
export default TimeUp;